:
<template>
    <div>
        <div class="d-flex flex-wrap my-3">
            <div class="col-12 col-lg-8 offset-lg-2 text-center">
                <h1 class="h2">
                    Caja para
                    {{ selectedMerchant.merchantType | merchantInfo("type") }}
                    <strong class="text-primary">
                        {{ selectedMerchant.name }}
                    </strong>
                </h1>
            </div>
            <div
                class="col-12 col-lg-2 d-flex justify-content-center align-items-center"
            >
                <vs-switch
                    v-if="$ability.hasScope('merchant:UpdateBox')"
                    v-model="enabledUpdate"
                    :class="enabledUpdate ? 'bg-primary' : 'bg-secondary'"
                    class="mr-1"
                >
                    🖊
                </vs-switch>
                <vs-button
                    circle
                    icon
                    floating
                    class="m-0"
                    @click="getBoxMerchantComponent"
                >
                    <reload-icon />
                </vs-button>
            </div>
        </div>

        <div class="d-flex mt-4 mx-3">
            En esta sección, puedes ver el balance de dinero en la caja. Además,
            tienes la capacidad de actualizar el balance de dinero de la caja.
        </div>
        <money-config-manage v-model="moneyConfig" :enabled="enabledUpdate" />
        <div
            v-if="enabledUpdate"
            class="col-12 d-flex justify-content-center mt-4"
        >
            <vs-button @click="updateBoxMerchantComponent">
                Actualizar caja
            </vs-button>
            <vs-button danger @click="resetBox"> Reiniciar caja </vs-button>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import { EMPTY_MONEY_CONFIG } from "@/common/lib/money";

import ReloadIcon from "@/components/icons/ReloadIcon.vue";
import MoneyConfigManage from "@/components/utils/MoneyConfigManage.vue";

export default {
    name: "BoxDetails",
    components: {
        MoneyConfigManage,
        ReloadIcon
    },
    data: () => ({
        moneyConfig: {},
        isLoading: false,
        loaderInstance: null,
        enabledUpdate: false
    }),
    computed: {
        ...mapState("commons", ["selectedMerchant"]),
        ...mapGetters("control", ["backgroundColor"])
    },
    watch: {},
    async mounted() {
        const verifyPageScope = this.$ability.verifyPageScope.bind(this);
        if (!(await verifyPageScope("merchant:GetBox", "/app", true))) return;
        await this.getBoxMerchantComponent();
    },
    methods: {
        ...mapActions("merchants", ["getBoxMerchant", "updateBoxMerchant"]),
        async getBoxMerchantComponent() {
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "corners" });
            try {
                const moneyConfig = await this.getBoxMerchant({
                    merchantId: this.selectedMerchant.merchantId,
                    merchantType: this.selectedMerchant.merchantType
                });
                this.moneyConfig = moneyConfig;
            } catch (error) {
                this.isLoading = false;
                this.loaderInstance.close();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.isLoading = false;
                this.loaderInstance.close();
            }
        },
        async resetBox() {
            const dialogResult = await this.$swal.fire({
                background: this.backgroundColor,
                title: "Vas a reiniciar la caja ?",
                text: "Si el balance de dinero en la caja quedará en 0. Estas seguro de la operación ?",
                icon: "info",
                showCancelButton: true,
                focusConfirm: false,
                confirmButtonText: "Si",
                cancelButtonText: "No 😖",
                confirmButtonColor: "#02913f",
                cancelButtonColor: "#f07325",
                allowOutsideClick: false
            });
            if (!dialogResult.isConfirmed) {
                return;
            }
            this.moneyConfig = EMPTY_MONEY_CONFIG;
            await this.updateBoxMerchantComponent();
        },
        async updateBoxMerchantComponent() {
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "corners" });
            try {
                await this.updateBoxMerchant({
                    merchantId: this.selectedMerchant.merchantId,
                    merchantType: this.selectedMerchant.merchantType,
                    moneyConfig: this.moneyConfig
                });
                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Actualización exitosa",
                    icon: "success",
                    showCloseButton: true
                });
            } catch (error) {
                this.loaderInstance.close();
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.loaderInstance.close();
                this.isLoading = false;
            }
        }
    }
};
</script>
